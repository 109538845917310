import React from 'react';
import { Box, Grid, Typography, Paper, Avatar, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import userDefaultImage from './../../assets/user.png'; // Fix the path as needed

export default function GalleryCard({ item }) {
  const navigate = useNavigate();

  const handleCardClick = async (postId) => {
    navigate(`/gallery/${postId}`);
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
      <ButtonBase
        onClick={() => handleCardClick(item.id, item.userId)}
        sx={{
          width: '100%',
          borderRadius: '4px',
          '&:hover': { cursor: 'pointer', boxShadow: '0 0px 2px 2px rgba(255,255,255, .3)' },
        }}
      >
        <Paper
          elevation={4}
          className={'gallery-item'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 1,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt={item.category}
                src={item.logo_image_url || userDefaultImage}
                sx={{
                  boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.1)',
                  marginRight: 1,
                }}
              />
              <Typography sx={{ fontSize: '.8rem' }}>{item.display_name}</Typography>
            </Box>
            <Typography sx={{ fontSize: '0.7rem', textAlign: 'right' }}>
              {formatTimestamp(item.created_at)}
            </Typography>
          </Box>

          <img
            height="100%"
            width="100%"
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: '4px',
              boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.1)',
              marginTop: '8px',
            }}
            src={item.image_url}
            alt={item.title}
          />
        </Paper>
      </ButtonBase>
    </Grid>
  );
}
