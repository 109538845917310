// const BASE_URL = 'http://localhost:7890';
const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth routes for dashboard
//...
/////////////////////////////////

//  public routes for gallery
// fetch all posts
export const fetchGalleryPosts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/gallery-posts`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch gallery posts');
    }
    const result = await response.json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// public route for post detail
export async function getGalleryPostDetail(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/gallery-posts/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const msg = await resp.json();
    return msg;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export const getAdditionalImageUrlsPublicIdsGallery = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/gallery-posts/urls/${id}`, {
      method: 'GET',
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
