/* eslint-disable no-console */
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function useStripeCustomer() {
  const fetchBillingPeriod = async () => {
    // if (!customerId) return;
    try {
      const url = `${BASE_URL}/api/v1/stripe/billing-period`;

      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const data = await resp.json();

      if (resp.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const checkStripeCustomer = async () => {
    // if (!awsSub) return;
    let result = { status: 200, data: null, error: null }; // default result

    try {
      const url = `${BASE_URL}/api/v1/auth/check-customer`;
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      result.status = resp.status;

      if (result.status === 401) {
        // If you specifically want to handle a 401 status, you can do it here.
        throw new Error(`HTTP error! Status: ${resp.status}`);
      } else if (result.status === 204) {
        // If you get a 204 status, it means there's no profile found, but it's not an error.
        // Do nothing, or if you need, set a flag or message to indicate the profile wasn't found.
        // For now, I'm just setting the data property to an appropriate message.
        result.data = 'No profile found';
      } else if (!resp.ok) {
        // For other non-2xx HTTP status codes:
        throw new Error(await resp.text());
      } else {
        result.data = await resp.json();
      }
    } catch (e) {
      console.error(e); //TODO should get a toast going on here not a console.error
      throw e;
    }

    return result;
  };

  // return { fetchStripeCustomer, fetchBillingPeriod, checkStripeCustomer };
  return { fetchBillingPeriod, checkStripeCustomer };
}
