import { useNavigate } from 'react-router-dom';
import useLoadingState from '../context/LoadingContext.js';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';

export default function useAppBar({ setAnchorElNav, setAnchorElUser }) {
  const { setPageLoading } = useLoadingState();

  const { customerId, setCustomerId } = useStripeContext();
  const {
    handleSignOut,
    setEmail,
    setPassword,
    setCPassword,
    email,
    setUser,
    setIsAuthenticated,
    // customerId,
    user,
  } = useAuthContext();
  const navigate = useNavigate();

  let timeout;

  const handleLogoNavigation = () => {
    setPageLoading(true);
    navigate('/');
    timeout = setTimeout(() => {
      setPageLoading(false);
    }, 0);
    return () => clearTimeout(timeout);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    switch (e.target.textContent) {
      case 'Gallery':
        setAnchorElNav(null);
        setPageLoading(true);
        navigate('/');
        timeout = setTimeout(() => {
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      case 'Sign Up':
        setAnchorElNav(null);
        setPageLoading(true);
        navigate('/auth/sign-up');
        timeout = setTimeout(() => {
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      case 'Sign In':
        setAnchorElNav(null);
        setPageLoading(true);
        navigate('/auth/sign-in');
        timeout = setTimeout(() => {
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      case 'About':
        setAnchorElNav(null);
        setPageLoading(true);
        timeout = setTimeout(() => {
          navigate('/about');
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      case 'Contact':
        setAnchorElNav(null);
        setPageLoading(true);
        timeout = setTimeout(() => {
          navigate('/contact');
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      default:
        setAnchorElNav(null);
        break;
    }
  };

  const handleCloseUserMenu = (e) => {
    switch (e.target.textContent) {
      case 'Logout':
        setPageLoading(true);
        handleSignOut(email, setUser, setIsAuthenticated, setCustomerId);
        setEmail('');
        setPassword('');
        setCPassword('');
        setAnchorElUser(null);
        navigate('/auth/sign-in');
        setPageLoading(false);
        return;
      case 'Subscription':
        if (customerId === null || customerId === undefined) {
          setPageLoading(true);
          navigate('/subscription');
          setAnchorElUser(null);
          timeout = setTimeout(() => {
            setPageLoading(false);
          }, 0);
          return () => clearTimeout(timeout);
        } else {
          setPageLoading(true);
          navigate('/dashboard');
          setAnchorElUser(null);
          timeout = setTimeout(() => {
            setPageLoading(false);
          }, 0);
          return () => clearTimeout(timeout);
        }
      case 'Dashboard':
        setPageLoading(true);
        navigate('/dashboard');
        setAnchorElUser(null);
        timeout = setTimeout(() => {
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      case 'Profile':
        setPageLoading(true);
        navigate(`/profile/${user}`);
        setAnchorElUser(null);
        timeout = setTimeout(() => {
          setPageLoading(false);
        }, 0);
        return () => clearTimeout(timeout);
      default:
        break;
    }
    setAnchorElUser(null);
  };

  return {
    handleLogoNavigation,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseNavMenu,
    handleCloseUserMenu,
  };
}
