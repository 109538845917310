import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useQuota } from '../../hooks/useQuota.js';
import Toast from '../Toast/Toast.js';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import QuotaGoals from '../Calendar/QuotaGoals.js';
import { editQuotaGoals } from '../../services/fetch-quota.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStripeContext } from '../../context/StripeContext.js';

import analysisMobileImageEx1 from '../../assets/analysis-ex-m-1.png';
import analysisMobileImageEx2 from '../../assets/analysis-ex-m-2.png';
import analysisDesktopImageEx from '../../assets/analysis-ex-dt.png';

Chart.register(annotationPlugin);

// TODO  Add cvs download button for data

const Analysis = ({ products, error, openToast, postError, loading }) => {
  const [monthlySummaries, setMonthlySummaries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const monthsPerPage = 6; // Number of months to display per page
  const productTypeColors = {
    auction: '#391bfc78',
    'direct-sale': '#478dff78',
    inventory: '#AAAAAA',
    'prep-other': 'rgba(0, 255, 0, 0.247)',
    'total-sale': '#333333',
    'quota-negative': 'rgba(255, 71, 71, 0.43)',
    'quota-positive': 'rgba(102, 237, 127, 0.533)',
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(monthlySummaries.length / monthsPerPage);

  // Slice the data for the current page
  const paginatedSummaries = monthlySummaries.slice(
    (currentPage - 1) * monthsPerPage,
    currentPage * monthsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [showQuotaLine, setShowQuotaLine] = useState(true);

  const { customerId } = useStripeContext();

  const { monthlyQuota, workdays, refreshQuotaData, quotaLoading, setQuotaLoading } =
    useQuota(customerId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [editMode, setEditMode] = useState(false);
  const [monthlyGoalInput, setMonthlyGoalInput] = useState();
  const [workingDaysInput, setWorkingDaysInput] = useState();

  const [chartReadyData, setChartReadyData] = useState([]);

  const [timeRange, setTimeRange] = useState('6months'); // Default is 6 months

  useEffect(() => {
    setMonthlyGoalInput(monthlyQuota);
    setWorkingDaysInput(workdays);
  }, [monthlyQuota, workdays]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        padding: 40,
        labels: {
          font: {
            size: isMobile ? 12 : 20, // Adjust the size for mobile
          },
          padding: isMobile ? 10 : 20, // Adjust padding for mobile
        },
        // Custom onClick function for handling the toggle of the User Quota line
        onClick: (e, legendItem, legend) => {
          const ci = legend.chart;

          if (legendItem.text === 'User Quota') {
            setShowQuotaLine(!showQuotaLine);
            // Manually toggle the 'hidden' property for the User Quota legend item
            legendItem.hidden = !legendItem.hidden;
          } else {
            const index = legendItem.datasetIndex;
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          }

          ci.update();
        },
      },

      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: monthlyQuota,
            yMax: monthlyQuota,
            borderColor: 'green',
            borderWidth: 4,
            borderDash: [10, 5],
            label: {
              content: 'Quota: $5500',
              enabled: true,
              position: 'start',
              textAlign: 'start',
              backgroundColor: 'rgba(0,0,0,0.8)',
              fontColor: '#ccc',
              fontSize: 16,
            },
            display: showQuotaLine,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: isMobile ? 12 : 20,
          },
        },
        grid: {
          color: '#CCCCCC', // Lighter color for better visibility
          lineWidth: 1, // Adjust line width as needed
        },
      },
      x: {
        ticks: {
          font: {
            size: isMobile ? 12 : 16,
          },
        },
        grid: {
          color: '#CCCCCC', // Lighter color for better visibility
          lineWidth: 1, // Adjust line width as needed
          drawOnChartArea: true, // Ensure that grid lines are drawn on the chart area
        },
      },
    },
    elements: {
      line: {
        tension: 0.3, // Smoothness of the line
      },
    },
  };

  useEffect(() => {
    if (products) {
      const processData = (products) => {
        const monthMap = {};

        // Create a new date for filtering
        const now = new Date();

        // Filter products based on time range
        let filteredProducts = products;

        if (timeRange === '6months') {
          const sixMonthsAgo = new Date(now); // Create a new instance to avoid mutating the original date
          sixMonthsAgo.setMonth(now.getMonth() - 6);
          filteredProducts = products.filter((item) => {
            const productDate = new Date(parseInt(item.date));
            return productDate >= sixMonthsAgo; // Filter products from last 6 months
          });
        } else if (timeRange === '1year') {
          const oneYearAgo = new Date(now);
          oneYearAgo.setFullYear(now.getFullYear() - 1);
          filteredProducts = products.filter((item) => {
            const productDate = new Date(parseInt(item.date));
            return productDate >= oneYearAgo; // Filter products from last 1 year
          });
        } else if (timeRange === '2years') {
          const twoYearsAgo = new Date(now);
          twoYearsAgo.setFullYear(now.getFullYear() - 2);
          filteredProducts = products.filter((item) => {
            const productDate = new Date(parseInt(item.date));
            return productDate >= twoYearsAgo; // Filter products from last 2 years
          });
        }

        filteredProducts.forEach((item) => {
          const date = new Date(parseInt(item.date));
          const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
          const price = parseFloat(item.price) || 0;

          if (!monthMap[monthYear]) {
            monthMap[monthYear] = {
              total: 0,
              counts: 0,
              subtotalAuctions: 0,
              subtotalDirectSales: 0,
              subtotalInventory: 0,
              totalSales: 0,
            };
          }

          monthMap[monthYear].total += price;
          monthMap[monthYear].counts++;

          switch (item.type) {
            case 'auction':
              monthMap[monthYear].subtotalAuctions += price;
              break;
            case 'direct-sale':
              monthMap[monthYear].subtotalDirectSales += price;
              break;
            case 'inventory':
              monthMap[monthYear].subtotalInventory += price;
              break;
          }
        });

        const unsortedData = Object.keys(monthMap).map((monthYear) => {
          const data = monthMap[monthYear];
          return {
            month: monthYear,
            total: data.total,
            dailyAvg: data.total / data.counts,
            quotaPercent: (data.total / monthlyQuota) * 100,
            subtotalAuctions: data.subtotalAuctions,
            subtotalDirectSales: data.subtotalDirectSales,
            subtotalInventory: data.subtotalInventory,
            totalSales: data.subtotalAuctions + data.subtotalDirectSales,
          };
        });

        const sortedData = [...unsortedData].sort((a, b) => {
          const [aMonth, aYear] = a.month.split('/').map(Number);
          const [bMonth, bYear] = b.month.split('/').map(Number);
          if (aYear !== bYear) {
            return aYear - bYear; // First sort by year
          } else {
            return aMonth - bMonth; // Then sort by month
          }
        });

        return sortedData;
      };

      const processedData = processData(products);

      setChartReadyData(processedData);
      setMonthlySummaries([...processedData].reverse()); // reverse for UI display
    }
  }, [products, monthlyQuota, timeRange]);

  // Function to prepare data for the chart
  const prepareChartData = (data) => {
    const labels = data.map((summary) => summary.month);
    const totalSalesData = data.map((summary) => summary.total);
    const auctionData = data.map((summary) => summary.subtotalAuctions);
    const directSaleData = data.map((summary) => summary.subtotalDirectSales);
    const subtotalInventory = data.map((summary) => summary.subtotalInventory);
    return {
      labels,
      datasets: [
        {
          label: 'User Quota',
          fill: false,
          borderColor: 'green',
          borderWidth: 5,
          borderDash: [10, 5],
        },
        {
          label: 'Auctions',
          data: auctionData,
          fill: false,
          borderColor: '#391bfc78',
          backgroundColor: '#391bfc78',
        },
        {
          label: 'Direct',
          data: directSaleData,
          fill: false,
          borderColor: '#478dff78',
          backgroundColor: '#478dff78',
        },
        {
          label: 'Website Inventory',
          data: subtotalInventory,
          fill: false,
          borderColor: 'grey',
          backgroundColor: 'grey  ',
        },
        {
          label: 'Total Value',
          data: totalSalesData,
          fill: false,
          borderColor: '#CCCCCC',
          borderWidth: 3,
          borderDash: [10, 5],
        },
      ],
    };
  };

  const getBackgroundColor = (total) => {
    if (total >= 150) return '#7d3cff'; // Purple for more than 100%
    if (total >= 100) return '#4caf50'; // Green for 75% to 100%
    if (total >= 75) return '#ffeb3b'; // Yellow for 50% to 75%
    if (total >= 50) return '#ff9800'; // Orange for 25% to 50%
    if (total >= 1) return '#f44336'; // Orange for 25% to 50%

    return;
  };

  const renderDesktopHeaders = () => {
    return (
      !isMobile && (
        <CardContent>
          <Grid container justifyContent="space-around" spacing={2}>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>Month</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Quota %
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Daily Avg
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Auctions
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Direct Sales
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Subtotal (Cash)
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Website Inventory
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ color: (theme) => theme.palette.primary.light }}>
                Total Value
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )
    );
  };
  if (openToast) {
    let toastMessage = error || postError;

    if (postError === 'Failed to fetch') {
      toastMessage = `Something went wrong fetching your posts. Please refresh the page or try again later.`;
    }

    const toast = { message: toastMessage };
    return <Toast toast={toast} />;
  }
  if (error || postError) {
    if (postError === 'Failed to fetch') {
      const toast = {
        message: `Something went wrong fetching your posts. Please refresh the page or try again later.`,
      };
      return <Toast toast={toast} />;
    } else if (error) {
      const toast = {
        message: error.message,
      };
      return <Toast toast={toast} />;
    }
  }

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    handleEditToggle();
    // Prepare the data to be sent
    const quotaData = {
      monthly_quota: monthlyGoalInput,
      work_days: workingDaysInput,
    };

    try {
      // Call the editQuotaGoals fetch function
      await editQuotaGoals(quotaData);
      await refreshQuotaData();
      // Optionally, show a success message or update the state/UI as needed

      // If you want to refresh the quota data, you can do so here
      // This depends on how your useQuota hook is set up and whether it needs refreshing
    } catch (error) {
      console.error('Error updating quota goals:', error);
      // Optionally, handle errors (e.g., show an error message)
    }
  };

  const renderMobileAccordion = (summary) => (
    <Accordion key={summary.month}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {/* Simplified summary for mobile view */}
        <Grid item xs={isMobile ? 4 : 1}>
          <Typography>{summary.month}</Typography>
        </Grid>
        <Grid
          item
          xs={isMobile ? 4 : 2}
          sx={{
            display: isMobile ? 'flex' : 'block',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              border: '2px solid white',
              borderColor: getBackgroundColor(summary.quotaPercent),
              borderRadius: '10px',
              padding: isMobile ? '5px 30px' : '2px 30px',
              justifyContent: 'flex-start',
              alignItems: 'center',
              transform: isMobile ? 'translate(30%, -12%)' : 'translate(0%, 5%)',
            }}
          >
            <Typography>{summary.quotaPercent.toFixed(0)}%</Typography>
            <Typography>${summary.total}</Typography>
          </Box>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {/* Full details for mobile view */}
        <>
          <Grid
            item
            xs={12}
            sx={{
              // backgroundColor: productTypeColors['auction'],
              border: '1px solid',
              borderColor: 'black',
              borderRadius: '10px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Daily Avg</Typography>
            <Typography sx={{ padding: '0' }}>${summary.dailyAvg.toFixed(0)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: productTypeColors['auction'],
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Auctions</Typography>

            <Typography sx={{ padding: '0' }}>${summary.subtotalAuctions.toFixed(0)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: productTypeColors['direct-sale'],
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Direct Sales</Typography>

            <Typography>${summary.subtotalDirectSales.toFixed(0)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: productTypeColors['total-sale'],
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Subtotal (Cash)</Typography>

            <Typography>${summary.totalSales.toFixed(0)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: productTypeColors['inventory'],
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Website Inventory</Typography>

            <Typography>${summary.subtotalInventory.toFixed(0)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '30px  0px',
              padding: '15px',
              height: '30px',
            }}
          >
            <Typography sx={{ padding: '0' }}>Total Value</Typography>

            <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
              ${summary.total}
            </Typography>
          </Grid>
        </>
      </AccordionDetails>
    </Accordion>
  );

  return (
    (quotaLoading && (
      <div className="loading-dash">
        <FlamePipe />
      </div>
    )) ||
    (!monthlySummaries.length || !chartReadyData.length ? (
      // Display images when no relevant data is available
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {isMobile ? (
          <>
            {' '}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                marginTop: '180px',
              }}
            >
              <Typography>No data saved to display yet. Example data below: </Typography>
              <img src={analysisMobileImageEx1} alt="Mobile Example 1" width="60%" />
              <img src={analysisMobileImageEx2} alt="Mobile Example 2" width="60%" />
            </Box>
          </>
        ) : (
          <>
            {' '}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                alignSelf: 'flex-start',
              }}
            >
              <Typography>No data saved to display yet. Example data below: </Typography>
              <img src={analysisDesktopImageEx} alt="Desktop Example" width="60%" />
            </Box>
          </>
        )}
      </Box>
    ) : (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: (theme) => theme.palette.primary.light,
          transform: 'translate(0%, -3%)',
          paddingX: (theme) => theme.spacing(2),
          paddingY: (theme) => theme.spacing(2),
          borderRadius: (theme) => theme.spacing(1),
          gap: (theme) => theme.spacing(2),
        }}
      >
        <QuotaGoals
          handleFormSubmit={handleFormSubmit}
          monthlyGoalInput={monthlyGoalInput}
          setMonthlyGoalInput={setMonthlyGoalInput}
          workingDaysInput={workingDaysInput}
          setWorkingDaysInput={setWorkingDaysInput}
          editMode={editMode}
          handleEditToggle={handleEditToggle}
          isMobile={isMobile}
          sx={{ marginBottom: '20px' }}
        />
        <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setTimeRange('6months');
              setCurrentPage(1);
            }}
            variant={timeRange === '6months' ? 'contained' : 'outlined'}
          >
            Last 6 months
          </Button>
          <Button
            onClick={() => {
              setTimeRange('1year');
              setCurrentPage(1);
            }}
            variant={timeRange === '1year' ? 'contained' : 'outlined'}
          >
            Last year
          </Button>
          <Button
            onClick={() => {
              setTimeRange('2years');
              setCurrentPage(1);
            }}
            variant={timeRange === '2years' ? 'contained' : 'outlined'}
          >
            Last 2 years
          </Button>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            marginTop: '5px',
            transform: isMobile ? 'translate(5%, 0%)' : 'translate(1%, 0%)',
          }}
        >
          {' '}
          {/* Chart Column */}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} component={Paper}>
            <Line
              data={prepareChartData(chartReadyData)}
              options={options}
              height={isMobile ? 400 : 200}
            />
          </Grid>
          {/* Data Table Column */}
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {/* Pagination controls */}
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
              <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography mx={2}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </Box>
            {isMobile ? (
              paginatedSummaries.map(renderMobileAccordion)
            ) : (
              <>
                {renderDesktopHeaders()}
                {paginatedSummaries.map((summary, index) => (
                  <Card key={index} sx={{ padding: '0', width: '100%', marginBottom: '5px' }}>
                    <CardContent sx={{ '&:last-child': { paddingBottom: '0' } }}>
                      <Grid
                        container
                        justifyContent="space-around"
                        spacing={2}
                        sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
                      >
                        <Grid item xs={isMobile ? 4 : 1}>
                          <Typography>{summary.month}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={isMobile ? 4 : 2}
                          sx={{
                            display: isMobile ? 'flex' : 'block',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'inline-block',
                              border: '2px solid white',
                              borderColor: getBackgroundColor(summary.quotaPercent),
                              borderRadius: '10px',
                              padding: isMobile ? '5px 30px' : '2px 30px',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              transform: isMobile ? 'translate(-20%, -12%)' : 'translate(0%, 5%)',
                            }}
                          >
                            <Typography>{summary.quotaPercent.toFixed(0)}%</Typography>
                            <Typography>${summary.total}</Typography>
                          </Box>
                        </Grid>
                        {!isMobile && (
                          // Additional columns for desktop ===============================================
                          <>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                // backgroundColor: productTypeColors['auction'],
                                border: '1px solid',
                                borderColor: 'black',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography sx={{ padding: '0' }}>
                                ${summary.dailyAvg.toFixed(0)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                backgroundColor: productTypeColors['auction'],
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography sx={{ padding: '0' }}>
                                ${summary.subtotalAuctions.toFixed(0)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                backgroundColor: productTypeColors['direct-sale'],
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography>${summary.subtotalDirectSales.toFixed(0)}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                backgroundColor: productTypeColors['total-sale'],
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography>${summary.totalSales.toFixed(0)}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                backgroundColor: productTypeColors['inventory'],
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography>${summary.subtotalInventory.toFixed(0)}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '30px  0px',
                                padding: '15px',
                                height: '30px',
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
                                ${summary.total}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    ))
  );
};

export default Analysis;
