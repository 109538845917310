const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch all products from database
export async function fetchProducts() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// create new product in database
export async function postProducts({
  type,
  date,
  title,
  description,
  category,
  price,
  image_url,
  public_id,
  num_days,
  post_id,
  sold,
}) {
  date = new Date(date);
  const milliseconds = date.getTime();
  date = Number(milliseconds);

  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        date,
        title,
        description,
        category,
        price,
        image_url,
        public_id,
        num_days,
        post_id,
        sold,
      }),
      credentials: 'include',
    });

    const msg = await resp.json();
    if (resp.ok) {
      return msg;
    } else {
      // eslint-disable-next-line no-console
      console.error(msg.message);
      throw new Error(msg.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// edit existing product in database
export async function editProducts({
  type,
  date,
  title,
  description,
  category,
  price,
  image_url,
  public_id,
  id,
  num_days,
  post_id,
  sold,
}) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        date,
        title,
        description,
        category,
        price,
        image_url,
        public_id,
        num_days,
        post_id,
        sold,
      }),
      credentials: 'include',
    });

    const msg = await resp.json();
    if (resp.ok) {
      return msg;
    } else {
      // eslint-disable-next-line no-console
      console.error(msg.message);
      throw new Error(msg.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteProduct(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const msg = await resp.json();
    if (resp.ok) {
      return msg;
    } else {
      // eslint-disable-next-line no-console
      console.error(msg.message);
      throw new Error(msg.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function uploadProductImage(image) {
  const formData = new FormData();
  formData.append('imageFiles', image[0]);

  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
