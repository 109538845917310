import { Box, Container } from '@mui/material';
import React from 'react';

const mobileImg = require('../../../../../src/assets/mobile-example.png');
const desktopImg = require('../../../../../src/assets/desktop-example.png');

export default function ExampleImages() {
  return (
    <Container className="example-images-wrapper" maxWidth="lg">
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: (theme) => theme.spacing(2),
        }}
      >
        <video controls width="100%" poster="your-video-poster.jpg">
          <source
            src="https://res.cloudinary.com/dzodr2cdk/video/upload/v1726302465/at-the-fire-ASSETS/at-the-fire-subscription-3_isgziq.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        {/* <Box>
          <img className="example-images" src={mobileImg} alt="mobile example" />
        </Box>
        <Box>
          <img className="example-images" src={desktopImg} alt="desktop example" />
        </Box> */}
      </Box>
    </Container>
  );
}
