import { useCallback, useEffect, useState } from 'react';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export function useProfile(sub) {
  const [error, setError] = useState('');
  const [profileLoading, setProfileLoading] = useState(true);

  // AWS Cognito related data:
  const [profile, setProfile] = useState({});

  // Strtipe related data:
  const [bizProfile, setBizProfile] = useState({});

  // Profile posts related data:
  const [posts, setPosts] = useState([]);

  const fetchProfile = useCallback(async () => {
    if (!sub) return;
    try {
      setProfileLoading(true);
      const resp = await fetch(`${BASE_URL}/api/v1/profile/${sub}`, {
        method: 'GET',
        credentials: 'include',
        cache: 'no-store', // Bypass cache to avoid old data
      });

      // Check if it's a 204 status (No Content)
      if (resp.status === 204) {
        setError('Profile not found.'); // Or any other message you find appropriate
        return; // Stop the execution of the function
      }

      if (!resp.ok) {
        // Handle other non-ok responses if needed
        throw new Error('Failed to fetch profile.'); // This will be caught by the catch block
      }

      const data = await resp.json();
      setProfile(data.profile);
      setBizProfile(data.bizProfile);
      setPosts(data.posts);
      setProfileLoading(false);
    } catch (e) {
      setError(e.message);
      setProfileLoading(false);
    }
  }, [sub]);

  useEffect(() => {
    // Using an immediately-invoked async function inside useEffect
    (async () => {
      await fetchProfile(); // Directly await the profile fetching
    })();
  }, [fetchProfile]);

  const updateProfile = async (updatedData) => {
    try {
      const resp = await fetch(`${BASE_URL}/api/v1/profile/user-update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedData),
      });

      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.message);
      }

      // Set the state directly from the update response
      setProfile(data.profile);
      // setBizProfile(data.bizProfile);

      // Then, re-fetch the data after the update to ensure frontend matches backend
      // Promise.all([fetchProfile(), fetchStripeCustomer()]).then(() => setProfileLoading(false));
      await fetchProfile();
      setProfileLoading(false);
    } catch (e) {
      setError(e.message);
      setProfileLoading(false);
    }
  };

  const updateBizProfile = async (updatedBizData) => {
    try {
      const resp = await fetch(`${BASE_URL}/api/v1/profile/customer-update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedBizData),
      });

      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.message);
      }

      // Assuming your backend returns the updated biz profile in the same structure
      setBizProfile(data);
    } catch (e) {
      // Here, you might want to set an error state to inform the user of the failed update
      //  another toast here?
      console.error(e); // You can replace this with a more user-friendly error message
    }
  };

  return {
    error,
    profileLoading,

    profile,
    bizProfile,
    updateProfile,
    updateBizProfile,
    posts,
    fetchProfile,
  };
}
