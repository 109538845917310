const BASE_URL = process.env.REACT_APP_BASE_URL;

/* Data functions */

// get all posts from database and display on admin page
export async function fetchPosts() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!resp.ok) {
      const errorResponse = await resp.json();
      const error = new Error('An error occurred');
      error.status = resp.status;
      error.response = errorResponse;
      throw error;
    }

    const data = await resp.json();

    // Check for restricted flag and handle accordingly
    if (data.restricted) {
      // Handle restricted access (e.g., disable UI elements, show a message, etc.)
      // console.log('Restricted access:', data.restricted);
    }

    return data;
  } catch (error) {
    console.error('Error in fetchPosts:', error.message);
    throw error;
  }
}

// create new post in database
export async function postPost(
  title,
  description,
  image_url,
  category,
  price,
  public_id,
  num_imgs,
  sold
) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        description,
        image_url,
        category,
        price,
        public_id,
        num_imgs,
        sold,
      }),
      credentials: 'include',
    });

    const msg = await resp.json();
    if (resp.ok) {
      return msg;
    } else {
      // eslint-disable-next-line no-console
      console.error(msg.message);
      throw new Error(msg.message);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

//upload image urls and public ids to db
export async function postAddImages(imageFiles, id) {
  try {
    const formData = new FormData();
    formData.append('image_urls', JSON.stringify(imageFiles.map((image) => image.secure_url)));
    formData.append('image_public_ids', JSON.stringify(imageFiles.map((image) => image.public_id)));
    formData.append(
      'resource_types',
      JSON.stringify(imageFiles.map((image) => image.resource_type))
    );
    // Append the id to the formData
    formData.append('id', id);
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/images`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    const msg = await resp.json();
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// function for transferring main image from gallery_posts to post_imgs
export async function transferProductPic(postId) {
  // TODO look into this... maybe the cause of the double img thing happening in create a post from quota tracking

  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/transfer`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        postId,
      }),
      credentials: 'include',
    });

    const msg = await resp.json();
  } catch (e) {
    console.error('Error transferring images');
  }
}

// delete single post from database
export async function deleteById(post_id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${post_id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const msg = await resp.json();

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// edit post called from EditPost
export async function updatePost(id, post) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, post }),
      credentials: 'include',
    });
    const msg = await resp.json();

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// return post detail (no image urls aside from the first one)
export async function getPostDetail(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const msg = await resp.json();
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// Cloudinary functions ---------------------------------------

//  Upload image files to Cloudinary
export const uploadImagesAndCreatePost = async (imageFiles, formFunctionMode) => {
  const formData = new FormData();
  imageFiles.forEach((file) => formData.append('imageFiles', file));
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    const result = await response.json();

    const image_urls = result.map((image) => image.secure_url);
    const public_ids = result.map((image) => image.public_id);

    let additionalImages = [];
    let newPost;
    let newImages = [];
    let editedPost;

    // create additionalImages WITH the default image
    if (formFunctionMode === 'new') {
      additionalImages = result.map((image) => ({
        public_id: image.public_id,
        secure_url: image.secure_url,
        resource_type: image.resource_type,
      }));

      // create new post object with default image url and public id,
      //  and any additional images
      newPost = {
        image_url: image_urls[0],
        public_id: public_ids[0],
        additionalImages,
      };

      return newPost;
    } else {
      newImages = result.map((image) => ({
        secure_url: image.secure_url,
        public_id: image.public_id,
        resource_type: image.resource_type,
      }));
      // create edited post object with new images
      editedPost = {
        newImages,
        additionalImages,
      };
      return editedPost;
    }

    // return newPost;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Upload user avatar image file to Cloudinary
export const uploadAvatarImage = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/avatar-upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    const result = await response.json();
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
// Upload customer logo  image file to Cloudinary
export const uploadLogoImage = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/logo-upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    const result = await response.json();
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const deleteImage = async (public_id, resource_type) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: public_id, resource_type: resource_type }),
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Delete avatar image from Cloudinary
export const deleteAvatarImage = async (publicId, resourceType) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/avatar-delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: publicId, resource_type: resourceType }),
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Delete logo image from Cloudinary
export const deleteLogoImage = async (publicId, resourceType) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/logo-delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: publicId, resource_type: resourceType }),
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Image data in our DB ---------------------------------------

// delete single image data from db
export const deleteImageData = async (id, public_id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/image/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: public_id }),
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Get additional image urls from in db
export const getAdditionalImageUrlsPublicIds = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/urls/${id}`, {
      method: 'GET',
      credentials: 'include',
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Public routes for gallery ---------------------------------------

// Public route for fetch all posts
export const fetchGalleryPosts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/main-gallery`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Public route for post detail
export async function getGalleryPostDetail(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/gallery-posts/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const msg = await resp.json();
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export const getAdditionalImageUrlsPublicIdsGallery = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/gallery-posts/urls/${id}`, {
      method: 'GET',
    });
    const result = await response.json();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Public route for search --------------------------------------- 9.5.23 THIS IS MY OLD SEARCH FUNCTION... needed?
export async function searchGalleryPosts(searchTerm) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/main-gallery/search/${searchTerm}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const msg = await resp.json();
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// TODO make equivalent for other tabs...
// Download inventory CSV file ---------------------------------------
export async function downloadInventoryCSV() {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/download-inventory-csv`, {
      credentials: 'include',
    });

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'inventory.csv';
    document.body.appendChild(a); // Append the anchor to body or it may not work in some browsers
    a.click();
    a.remove(); // Clean up
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during fetch or download:', error);
    // Handle the error appropriately here
  }
}
