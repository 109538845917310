/* eslint-disable no-console */
import { createContext, useContext, useEffect, useState } from 'react';
// import { userPool } from '../services/userPool.js';
// import useAuth from '../hooks/useAuth.js';
// import useLoadingState from './LoadingContext.js';
// import useStripeCustomer from '../hooks/useStripeCustomer.js';
// import { createCookies } from '../services/cookieAPI.js';

const AccountContext = createContext();
// const BASE_URL = process.env.REACT_APP_BASE_URL;
const AccountProvider = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isActive, setIsActive] = useState(null);
  // const [user, setUser] = useState(null);
  // const [customerId, setCustomerId] = useState(null);
  // const [error, setError] = useState(null);
  // const [email, setEmail] = useState('');

  // const [stripeEmail, setStripeEmail] = useState('sam');
  // const [stripeName, setStripeName] = useState('harris');

  // const [password, setPassword] = useState('');
  // const [cPassword, setCPassword] = useState('');

  // const { loginLoading, setLoginLoading } = useLoadingState();
  // const { checkStripeCustomer } = useStripeCustomer();

  // const cognitoUser = userPool.getCurrentUser();

  // const [loadingAuth, setLoadingAuth] = useState();
  // const [loadingCustomerId, setLoadingCustomerId] = useState(false);

  const [openToast, setOpenToast] = useState(false);

  // const [cookiesSet, setCookiesSet] = useState(false);
  // const { fetchAuth, handleSignOut } = useAuth({ setUser, setError, setCookiesSet });

  // const [profileUpdated, setProfileUpdated] = useState(true);

  // const [tokenExpiryTime, setTokenExpiryTime] = useState(null);

  // const [accessToken, setAccessToken] = useState(null);

  // const [subscription, setSubscription] = useState(null);
  // check for user  and authenticate  =====================================================
  // useEffect(() => {
  //   if (
  //     !cognitoUser ||
  //     customerId ||
  //     isAuthenticated === true ||
  //     loginLoading === true ||
  //     loadingAuth === true
  //   ) {
  //     return;
  //   }

  // async function authenticateUser() {
  //   if (cognitoUser !== null || cookiesSet === false || isAuthenticated === false) {
  //     try {
  //       setLoadingAuth((prev) => !prev);

  //       const session = await new Promise((resolve, reject) => {
  //         cognitoUser.getSession((e, s) => (e ? reject(e) : resolve(s)));
  //       });
  //       if (session.isValid()) {
  //         setAccessToken(session.accessToken.jwtToken); // this is part of proof of concept used in /dashboard
  //         setUser(cognitoUser.getUsername());
  //         setEmail(session.getIdToken().payload.email);
  //         const expiryTimeInSeconds = session.getIdToken().getExpiration();
  //         setTokenExpiryTime(expiryTimeInSeconds);
  //         // Now, try setting cookies if not set yet
  //         if (!cookiesSet) {
  //           try {
  //             await createCookies(session);
  //             setCookiesSet(true); // Update state to reflect cookies are set
  //           } catch (cookieError) {
  //             console.error('Error setting cookies:', cookieError);
  //             // Handle cookie setting error
  //           }
  //         }
  //         setIsAuthenticated(true);
  //         setLoadingAuth(false);
  //         setLoginLoading(false);
  //       } else {
  //         setIsAuthenticated(false);
  //         throw new Error('Session is not valid');
  //       }
  //     } catch (error) {
  //       console.error('Failed authentication', error);
  //       setIsAuthenticated(false);
  //       // Additional error handling related to authentication failure
  //     }
  //   } else {
  //     return;
  //   }
  // }

  //   authenticateUser();
  // }, [
  //   cognitoUser,
  //   cookiesSet,
  //   isAuthenticated,
  //   loadingAuth,
  //   customerId,
  //   loginLoading,
  //   setLoginLoading,
  // ]);

  // refresh tokens on timer =====================================================
  // useEffect(() => {
  //   let timerId;

  //   const logRemainingTime = async () => {
  //     if (tokenExpiryTime) {
  //       const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  //       const timeLeftInSeconds = tokenExpiryTime - currentTimeInSeconds;

  //       // Refresh tokens if there are less than 5 minutes left until expiry time *value set in AWS Cognito User Pool*
  //       if (timeLeftInSeconds <= 295) {
  //         await refreshTokens();
  //         clearInterval(timerId); // Clear timer if the token has expired
  //       }
  //     }
  //   };

  //   if (tokenExpiryTime) {
  //     timerId = setInterval(logRemainingTime, 1000); // Log every second
  //   }

  //   return () => {
  //     clearInterval(timerId); // Cleanup timer on component unmount
  //   };
  // }, [tokenExpiryTime]);

  // refresh tokens on page load =====================================================
  // useEffect(() => {
  //   async function handleTokenRefresh() {
  //     if (isAuthenticated && cookiesSet && user) {
  //       try {
  //         await refreshTokens(); // Define your logic to refresh tokens
  //       } catch (error) {
  //         console.error('Error refreshing tokens:', error);
  //       }
  //     }
  //   }

  //   handleTokenRefresh();
  // }, [isAuthenticated, cookiesSet, user]);

  // check for stripe customer id  =====================================================
  // useEffect(() => {
  //   if (
  //     !user ||
  //     !isAuthenticated ||
  //     !cookiesSet ||
  //     customerId ||
  //     loadingCustomerId ||
  //     loginLoading
  //   ) {
  //     setLoadingCustomerId(false);
  //     return;
  //   }

  //   async function getStripeCustomerDetails() {
  //     // New flag to handle first-time purchase scenario
  //     const isFirstPurchase = !customerId;

  //     if (
  //       (user && isAuthenticated && cookiesSet && loadingCustomerId === false) ||
  //       loginLoading === true
  //     ) {
  //       setLoadingCustomerId(true);
  //       setLoginLoading((prev) => !prev);

  //       try {
  //         const stripeCustomer = await checkStripeCustomer();

  //         setStripeName(stripeCustomer.data.name);
  //         setStripeEmail(stripeCustomer.data.email);

  //         if (stripeCustomer.data.subscription) {
  //           setSubscription(stripeCustomer.data.subscription.isActive);
  //         }

  //         if (stripeCustomer.data && stripeCustomer.data.customerId) {
  //           setCustomerId(stripeCustomer.data.customerId);
  //           setLoadingCustomerId(false);
  //           setLoginLoading(false);
  //         }
  //       } catch (e) {
  //         console.error('Error caught:', e);
  //         // Handle the errors specifically related to Stripe customer checking
  //       }
  //     } else {
  //       if (isFirstPurchase) {
  //         console.log('FIRST PURCHASE IF STATEMENT CUSTOMER NULL');

  //         setCustomerId(null);
  //       }
  //       return;
  //     }
  //   }

  //   getStripeCustomerDetails();
  // }, [
  //   checkStripeCustomer,
  //   user,
  //   isAuthenticated,
  //   cookiesSet,
  //   loadingAuth,
  //   customerId,
  //   loadingCustomerId,
  //   loginLoading,
  //   setLoginLoading,
  //   stripeEmail,
  //   stripeName,
  // ]);

  // handle sign up/ sign in =====================================================
  // const handleRegistration = async (e, type) => {
  //   // setLoginLoading(true);
  //   e.preventDefault();
  //   if (type === 'sign-up' && password !== cPassword) {
  //     setError({ message: 'Passwords do not match' });
  //     setOpenToast(true);
  //     return;
  //   } else {
  //     try {
  //       const result = await fetchAuth({ email, password, type });
  //       if (result) {
  //         setIsAuthenticated(true);
  //         setPassword('');
  //         setCPassword('');
  //         setError(null);
  //         setOpenToast(false);
  //       } else {
  //         setIsAuthenticated(false);
  //       }
  //     } catch (e) {
  //       setError({ message: e.message });

  //       if (e && e.code === 'UsernameExistsException') {
  //         setOpenToast(true);
  //       } else if (e === 'Email already exists.') {
  //         setOpenToast(true);
  //       } else if (e === 'Passwords do not match') {
  //         setOpenToast(true);
  //       } else {
  //         // this is a catch all for any other error
  //         setOpenToast(true);
  //       }

  //       setIsAuthenticated(false);
  //     }
  //   }

  //   // setTimeout(() => {
  //   setLoadingAuth(false);
  //   // setLoginLoading(false);
  //   // }, 1000);
  // };
  // refresh tokens logic =====================================================
  // async function refreshTokens() {
  //   try {
  //     const response = await fetch(`${BASE_URL}/api/v1/auth/refresh-tokens`, {
  //       method: 'POST',
  //       credentials: 'include', // Important to include cookies
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to refresh tokens');
  //     }
  //     const data = await response.json();
  //     const newExpiryTimeInSeconds = data.accessTokenExpiry; // Assuming this is the expiry time
  //     setTokenExpiryTime(newExpiryTimeInSeconds); // Update state with new token expiry time

  //     // You can update local state or do other tasks here as needed
  //   } catch (error) {
  //     console.error('Error refreshing tokens:', error);
  //     // Handle error, e.g., redirect to login page, show error message, etc.
  //   }
  // }

  return (
    <AccountContext.Provider
      value={{
        // fetchAuth,
        // handleSignOut,
        // handleRegistration,
        // user,
        // customerId,
        // setCustomerId,
        // isAuthenticated,
        // setIsActive,
        // isActive,
        // setIsAuthenticated,
        // setUser,
        // setError,
        // error,
        // email,
        // setEmail,
        // password,
        // setPassword,
        // cPassword,
        // setCPassword,
        // loadingAuth,
        openToast,
        setOpenToast,
        // setProfileUpdated,
        // profileUpdated,
        // subscription,
        // setSubscription,
        // stripeEmail,
        // setStripeEmail,
        // stripeName,
        // setStripeName,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

export { AccountProvider, useAccount };
