import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { fetchQuotaGoals } from '../services/fetch-quota.js';

export function useQuota(customerId) {
  const [monthlyQuota, setMonthlyQuota] = useState([]);
  const [workdays, setWorkdays] = useState([]);
  const [quotaLoading, setQuotaLoading] = useState(true);
  const [quotaError, setQuotaError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setQuotaLoading(true);
        const data = await fetchQuotaGoals();

        setMonthlyQuota(data[0].monthly_quota);
        setWorkdays(data[0].work_days);

        setQuotaLoading(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          console.error(e.response.data.message);
        }
        setQuotaError(e.message);
        setQuotaLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  const refreshQuotaData = useCallback(async () => {
    try {
      const data = await fetchQuotaGoals();
      setMonthlyQuota(data[0].monthly_quota);
      setWorkdays(data[0].work_days);
    } catch (e) {
      // handle errors
      setQuotaError(e.message);
    } finally {
      setQuotaLoading(false);
    }
  }, [customerId]); // Dependency array

  useEffect(() => {
    refreshQuotaData();
  }, [refreshQuotaData]); // useEffect now depends on refreshQuotaData, which itself depends on customerId

  return {
    monthlyQuota,
    setMonthlyQuota,
    workdays,
    setWorkdays,
    quotaLoading,
    setQuotaLoading,
    quotaError,
    setQuotaError,
    refreshQuotaData, // export this function
  };
}
