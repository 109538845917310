/* eslint-disable no-console */
import { AmazonCognitoIdentity, userPool } from '../services/userPool.js';
import { createCookies, deleteCookies } from '../services/cookieAPI.js';

export default function useAuth({ setUser, setError, setCookiesSet }) {
  const fetchAuth = async ({ email, password, type }) => {
    if (type === 'sign-up') {
      try {
        const result = await new Promise((resolve, reject) => {
          const attributeList = [
            new AmazonCognitoIdentity.CognitoUserAttribute({
              Name: 'email',
              Value: email,
            }),
          ];

          userPool.signUp(email, password, attributeList, null, (e, result) => {
            if (e) {
              reject(e);
            } else {
              resolve(result);
            }
          });
        });
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        // fetch call to our server to enter email and sub into our database
        const resp = await fetch(`${BASE_URL}/api/v1/auth/new-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, sub: result.userSub }),
        });
        const data = await resp.json();
        if (resp.ok) {
          localStorage.setItem('needsVerification', 'true');
          window.location.replace('/auth/sign-in');
          return data;
        } else {
          throw data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    } else if (type === 'sign-in') {
      try {
        return new Promise((resolve, reject) => {
          const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password,
          });

          const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: email,
            Pool: userPool,
          });

          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async (result) => {
              setUser(cognitoUser.username);
              await createCookies(result);
              resolve(result);
            },
            onFailure: (e) => {
              reject(e);
              console.error('useAuth onFailure:', e);
              setError(e.message);
            },
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleForgotPassword = ({
    code,
    stage,
    setStage,
    email,
    password,
    setPassword,
    cPassword,
    setCPassword,
  }) => {
    const getUser = () => {
      return new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      });
    };

    const sendVerificationCode = () => {
      getUser().forgotPassword({
        onSuccess: (result) => {
          console.info('on success', result);
        },
        onFailure: (e) => {
          console.error(e);
        },
        inputVerificationCode: (data) => {
          console.info(data);
          setStage(1);
        },
      });
    };

    const resetPassword = () => {
      if (password !== cPassword) {
        setError('Passwords do not match');
        console.error('Passwords do not match');
        return;
      }
      getUser().confirmPassword(code, password, {
        onSuccess: (data) => {
          console.info('onSuccess:', data);
          setStage(2);
          setPassword('');
          setCPassword('');
        },
        onFailure: (e) => {
          setError(e.message);
          console.error('onFailure:', e.message);
        },
      });
    };

    stage === 0 && sendVerificationCode();
    stage === 1 && resetPassword();
  };

  // const handleSignOut = async (signOutEmail, setUser, setIsAuthenticated) => {
  const handleSignOut = async (signOutEmail, setUser, setIsAuthenticated, setCustomerId) => {
    try {
      return new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: signOutEmail,
          // for some reason ⬆ this appears to be optional, as it works with just a blank string.
          // I moved all of the email and password state to the account context in order to pass
          // the email down to the sign out function in the navbar
          Pool: userPool,
        });

        cognitoUser.signOut();

        // You could also check the current session to ensure the user is signed out

        cognitoUser.getSession(async (e, session) => {
          if (e || !session.isValid()) {
            setUser(null);
            setIsAuthenticated(false);
            setCustomerId(null);

            // delete cookies and session data from cookies/local storage
            window.localStorage.clear();
            await deleteCookies();
            setCookiesSet(false);
            window.location.reload();
            resolve();
          } else {
            reject('User is still signed in');
          }
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  return { fetchAuth, handleSignOut, handleForgotPassword };
}
