import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CssBaseline, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import Dashboard from '../Dashboard/Dashboard.js';
import QuotaTracking from '../QuotaTracking/QuotaTracking.js';
import Analysis from '../Analysis/Analysis.js';
import { useProducts } from '../../hooks/useProducts.js';
import { useAccount } from '../../context/AccountContext.js';
import Calendar from '../Calendar/Calendar.js';
import { useNavigate } from 'react-router-dom';
import { usePosts } from '../../hooks/usePosts.js';
import InventoryTracking from '../InventoryTracking/InventoryTracking.js';
import { useQuota } from '../../hooks/useQuota.js';
import { postNewSnapshot } from '../../services/fetch-inventory.js';
import { useInventory } from '../../hooks/useInventory.js';
import Orders from '../Orders/Orders.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { useStripeContext } from '../../context/StripeContext.js';
import { useTheme } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const navigate = useNavigate();
  const { isAuthenticated, error } = useAuthContext();

  // console.log('customerId in DashboardTabs', customerId);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
      return;
    }
  }, [isAuthenticated, navigate, error]);
  return (
    <div
      style={{ marginTop: '0px' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function createTabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const { openToast } = useAccount();
  const { customerId } = useStripeContext();
  const { error } = useAuthContext();
  const { snapshots, setSnapshots, inventoryLoading, refreshInventoryData } = useInventory();
  const {
    products,
    setProducts,
    // loadingProducts,
    // setLoadingProducts,
    // productError,
    // setProductError,
  } = useProducts();
  const { monthlyQuota, workdays, refreshData, setQuotaLoading, quotaLoading } =
    useQuota(customerId);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { posts, setLoading, loading, setPosts, postError } = usePosts();
  const theme = useTheme();

  // const isLessThanExtraLarge = useMediaQuery(theme.breakpoints.down('xl'));

  // state/ functions for inventory tracking
  let postCategoryCounts = {};
  let postPriceCounts = {};
  for (let post of posts) {
    // Handle category count

    if (postCategoryCounts[post.category]) {
      postCategoryCounts[post.category]++;
    } else {
      postCategoryCounts[post.category] = 1;
    }
    // Handle price count

    if (postPriceCounts[post.category]) {
      postPriceCounts[post.category] += parseFloat(post.price) || 0;
    } else {
      postPriceCounts[post.category] = parseFloat(post.price) || 0;
    }
  }

  const handleSaveSnapshot = async () => {
    const snapshot = {
      category_count: postCategoryCounts,
      price_count: postPriceCounts,
    };

    try {
      const newSnapshot = await postNewSnapshot(snapshot);

      if (newSnapshot.willBeNewSnapShot === false) {
        toast.warn('No new data to display', { theme: 'dark' });
        return;
      }
      setSnapshots((prevSnapshots) => [...prevSnapshots, newSnapshot]);
      setTableData;
    } catch (error) {
      console.error('Error saving snapshot:', error);
    }
  };

  // TODO trying to get snapshots to update when a new post is created  - haven't figured it out yet 12/4
  // console.log('posts in DashboardTabs', posts);
  // console.log('snapshots in DashboardTabs', snapshots);
  // useEffect(() => {
  //   // refreshInventoryData();
  //   handleSaveSnapshot();
  // });
  const [tableData, setTableData] = useState({});
  return (
    <Box sx={{ margin: '80px auto 0 auto', width: '100%' }}>
      <CssBaseline />
      <Box sx={{ borderBottom: 0, borderColor: 'divider', height: '55px', margin: '0px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            transform: 'translate(0%, -8%)',
          }}
          // centered // doesn't seem to work when variant is scrollable
          // ... other styles ...
        >
          <Tab label="Dashboard" {...createTabs(0)} />
          <Tab label="Inventory Tracking" {...createTabs(1)} />
          <Tab label="Orders" {...createTabs(2)} />
          <Tab label="Quota Tracking" {...createTabs(3)} />
          <Tab label="Calendar" {...createTabs(4)} />
          <Tab label="Analysis" {...createTabs(5)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Dashboard
          products={products}
          setProducts={setProducts}
          error={error}
          openToast={openToast}
          postError={postError}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InventoryTracking
          customerId={customerId}
          postCategoryCounts={postCategoryCounts}
          postPriceCounts={postPriceCounts}
          error={error}
          openToast={openToast}
          postError={postError}
          loading={loading}
          handleSaveSnapshot={handleSaveSnapshot}
          tableData={tableData}
          setTableData={setTableData}
          snapshots={snapshots}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Orders
          // products={products}
          // monthlyQuota={monthlyQuota}
          error={error}
          openToast={openToast}
          // postError={postError}
          // loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <QuotaTracking
          products={products}
          setProducts={setProducts}
          error={error}
          openToast={openToast}
          postError={postError}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Calendar
          products={products}
          monthlyQuota={monthlyQuota}
          error={error}
          openToast={openToast}
          postError={postError}
          setQuotaLoading={setQuotaLoading}
          quotaLoading={quotaLoading}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Analysis
          products={products}
          monthlyQuota={monthlyQuota}
          error={error}
          openToast={openToast}
          postError={postError}
          loading={loading}
          sx={{ width: '100%', height: '100%' }}
        />
      </TabPanel>
    </Box>
  );
}
