const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch all products from database
export async function fetchQuotaGoals() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/goals?`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      // console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// edit existing product in database
export async function editQuotaGoals(quotaData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/quota-tracking/goals`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quotaData,
      }),
      credentials: 'include',
    });

    const msg = await resp.json();
    if (resp.ok) {
      return msg;
    } else {
      // eslint-disable-next-line no-console
      console.error(msg.message);
      throw new Error(msg.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
