import React from 'react';
import './Toast.css';
import { Link } from 'react-router-dom';

function Toast({ toast }) {
  if (!toast || !toast.message) {
    return null;
  }
  console.log('toast.message===============', toast.message);

  let additionalMessage = 'Try refreshing the page. If the problem persists please contact ';
  if (
    toast.message === 'Incorrect username or password.' ||
    toast.message === 'An account with the given email already exists.' ||
    toast.message === 'Passwords do not match'
  ) {
    additionalMessage = 'Please try again or contact ';
  } else if (toast.message === 'Token has expired!') {
    additionalMessage = 'Please login again or contact ';
  }

  return (
    <div className="toast show">
      {toast.message}{' '}
      <span>
        {additionalMessage} <Link to="/contact">support</Link>.
      </span>
    </div>
  );
}

export default Toast;
