import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProducts } from '../services/fetch-products.js';

export function useProducts() {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productError, setProductError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingProducts(true);
        const data = await fetchProducts();

        setProducts(data);
        setLoadingProducts(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          // eslint-disable-next-line no-console
          console.error(e.response.data.message);
        }
        setProductError(e.message);
        setLoadingProducts(false);
      }
    };
    fetchData();
  }, []);

  return {
    products,
    setProducts,
    loadingProducts,
    setLoadingProducts,
    productError,
    setProductError,
  };
}
