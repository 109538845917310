import { createContext, useContext, useEffect, useState } from 'react';
import { fetchGalleryPosts } from '../services/fetchPosts.js';
import useLoadingState from './LoadingContext.js';

const QueryContext = createContext();

const QueryProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const [galleryPosts, setGalleryPosts] = useState([]);
  const [error, setError] = useState(false);
  const [newPostCreated, setNewPostCreated] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const { setLoadingGalleryResults } = useLoadingState();

  const [queryLoading, setQueryLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setQueryLoading(true);
        const data = await fetchGalleryPosts();

        setGalleryPosts(data);
        setQueryLoading(false);
      } catch (error) {
        setFetchError('Failed to fetch gallery posts.');
        console.error(error);
      }
    };

    fetchData();
  }, [newPostCreated]);

  const searchQuery = (query || '').toLowerCase();

  const filteredData = galleryPosts.filter((item) => {
    const itemTitle = item.title ? item.title.toLowerCase() : '';
    const itemArtist = item.display_name ? item.display_name.toLowerCase() : '';
    const itemDescription = item.description ? item.description.toLowerCase() : '';
    const itemCategory = item.category ? item.category.toLowerCase() : '';
    return (
      itemTitle.includes(searchQuery) ||
      itemArtist.includes(searchQuery) ||
      itemDescription.includes(searchQuery) ||
      itemCategory.includes(searchQuery)
    );
  });

  useEffect(() => {
    if (filteredData.length === 0) {
      setError(true);
    } else {
      setError(null);
    }
  }, [filteredData]);

  return (
    <QueryContext.Provider
      value={{
        query,
        error,
        setQuery,
        filteredData,
        setNewPostCreated,
        fetchError,
        setGalleryPosts,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

const useQuery = () => {
  const context = useContext(QueryContext);
  if (context === undefined) {
    throw new Error('useQuery must be used within a QueryProvider');
  }
  return context;
};

export { QueryProvider, useQuery };
