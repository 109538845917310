import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Auth.css';
import { Box, Container, CircularProgress, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAccount } from '../../context/AccountContext.js';
import SignUp from './AuthForms.js/SignUp.js';
import SignIn from './AuthForms.js/SignIn.js';
import ForgotPassword from './AuthForms.js/ForgotPassword.js';
import useLoadingState from '../../context/LoadingContext.js';
import ChangeAuthForm from './AuthForms.js/ChangeAuthForm.js';
import Toast from '../Toast/Toast.js';
import { useAuthContext } from '../../context/AuthContext.js';

export default function Auth() {
  const { type } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isAuthenticated, error } = useAuthContext();

  const { loginLoading, pageLoading, setLoginLoading } = useLoadingState();
  const [showToast, setShowToast] = useState(false);

  // for sign up- if user hasn't verified email yet, show toast
  useEffect(() => {
    const needsVerification = localStorage.getItem('needsVerification');

    if (needsVerification === 'true') {
      setShowToast(true);
      localStorage.removeItem('needsVerification');
      const timer = setTimeout(() => setShowToast(false), 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');

      return;
    }
  }, [isAuthenticated, navigate]);

  const authForms = () => {
    switch (type) {
      case 'sign-in':
        return <SignIn />;

      case 'sign-up':
        return <SignUp />;

      case 'forgot-password':
        return <ForgotPassword />;

      default:
        break;
    }
  };
  const { openToast, setOpenToast } = useAccount();

  useEffect(() => {
    if (error) {
      if (error && error.code === 'UsernameExistsException') {
        setOpenToast(true);
      } else if (error === 'Email already exists.') {
        setOpenToast(true);
      } else if (error === 'Passwords do not match') {
        setOpenToast(true);
      }
    }
  }, [error, setOpenToast]);

  return (
    <>
      {openToast && <Toast toast={error} />}
      {showToast && (
        <div className="sign-up-toast">Please check your email to verify your account!</div>
      )}

      {!loginLoading && !pageLoading ? (
        <Box className={'auth-form-wrapper'}>
          <Container maxWidth={'xs'} className={'auth-form-container'}>
            <Box className="form-container" component={'form'} onSubmit={(e) => e.preventDefault()}>
              <Paper className="form-container-paper" elevation={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                    height: '100%',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {authForms()}
                </Box>

                <Container maxWidth="sm" className="sign-in-sign-out">
                  <ChangeAuthForm type={type} />
                </Container>
              </Paper>
            </Box>
          </Container>
        </Box>
      ) : (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '49%',
            left: '49%',
          }}
        />
      )}
    </>
  );
}
