import React, { createContext, useContext, useEffect, useState } from 'react';
import { userPool } from '../services/userPool.js';
import { createCookies } from '../services/cookieAPI.js';
import useLoadingState from './LoadingContext';
import useAuth from '../hooks/useAuth';
import useStripeCustomer from '../hooks/useStripeCustomer.js';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [email, setEmail] = useState('');
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [tokenExpiryTime, setTokenExpiryTime] = useState(null);
  const [cookiesSet, setCookiesSet] = useState(false);
  const [error, setError] = useState(null);
  const { loginLoading, setLoginLoading } = useLoadingState();
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [customerId, setCustomerId] = useState(null); // <-- Managing customerId here
  const { checkStripeCustomer } = useStripeCustomer(); // Assuming this checks the customer ID

  const { fetchAuth, handleSignOut, handleForgotPassword } = useAuth({
    setUser,
    setError,
    setCookiesSet,
  });

  const handleRegistration = async (e, type, password, cPassword) => {
    e.preventDefault();
    if (type === 'sign-up' && password !== cPassword) {
      setError({ message: 'Passwords do not match' });
      return;
    }

    try {
      const result = await fetchAuth({ email, password, type });
      if (result) {
        setIsAuthenticated(true);
        setPassword('');
        setCPassword('');
        setError(null);
      } else {
        setIsAuthenticated(false);
      }
    } catch (e) {
      setError({ message: e.message });
      setIsAuthenticated(false);
    }

    setLoadingAuth(false);
  };

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser || isAuthenticated || loginLoading || loadingAuth) return;

    async function authenticateUser() {
      setLoadingAuth(true);
      try {
        const session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => (err ? reject(err) : resolve(session)));
        });

        if (session.isValid()) {
          setAccessToken(session.accessToken.jwtToken);
          setUser(cognitoUser.getUsername());
          setEmail(session.getIdToken().payload.email);
          setTokenExpiryTime(session.getIdToken().getExpiration());

          // Fetch customer ID
          const customerData = await checkStripeCustomer();
          setCustomerId(customerData?.data?.customerId || null);
          if (!cookiesSet) {
            await createCookies(session);
            setCookiesSet(true);
          }

          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setError('Failed authentication');
        console.error('Failed authentication', error);
        setIsAuthenticated(false);
      } finally {
        setLoadingAuth(false);
        setLoginLoading(false);
      }
    }

    authenticateUser();
  }, []);

  useEffect(() => {
    let timerId;

    const logRemainingTime = async () => {
      if (tokenExpiryTime) {
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const timeLeftInSeconds = tokenExpiryTime - currentTimeInSeconds;

        if (timeLeftInSeconds <= 295) {
          await refreshTokens();
          clearInterval(timerId);
        }
      }
    };

    if (tokenExpiryTime) {
      timerId = setInterval(logRemainingTime, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [tokenExpiryTime]);

  async function refreshTokens() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/refresh-tokens`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to refresh tokens');
      }

      const data = await response.json();
      setTokenExpiryTime(data.accessTokenExpiry);
    } catch (error) {
      setError('Error refreshing tokens');
      console.error('Error refreshing tokens:', error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated, // added late for useAppBar.... make sure it's necessary
        user,
        setUser,
        email,
        setEmail,
        loadingAuth,
        handleSignOut,
        fetchAuth,
        handleRegistration,
        handleForgotPassword,
        accessToken,
        error,
        setError,
        password,
        setPassword,
        cPassword,
        setCPassword,
        customerId,
        setCustomerId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('massive fuck up');
  }
  return context;
};
export { AuthProvider, useAuthContext };
