import React, { createContext, useContext, useEffect, useState } from 'react';
import useStripeCustomer from '../hooks/useStripeCustomer.js';
import { useAuthContext } from '../context/AuthContext'; // Import the Auth context to monitor login state

const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [customerId, setCustomerId] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [stripeEmail, setStripeEmail] = useState('');
  const [stripeName, setStripeName] = useState('');
  const [loadingCustomerId, setLoadingCustomerId] = useState(false);

  const { checkStripeCustomer } = useStripeCustomer();
  const { isAuthenticated, user } = useAuthContext(); // Get authentication state from AuthContext

  useEffect(() => {
    const fetchStripeCustomerDetails = async () => {
      if (isAuthenticated && user) {
        // Only fetch if user is authenticated
        setLoadingCustomerId(true);
        try {
          const stripeCustomer = await checkStripeCustomer();

          setStripeName(stripeCustomer.data.name);
          setStripeEmail(stripeCustomer.data.email);

          if (stripeCustomer.data.subscription) {
            setSubscription(stripeCustomer.data.subscription.isActive);
          }

          if (stripeCustomer.data && stripeCustomer.data.customerId) {
            setCustomerId(stripeCustomer.data.customerId);
          } else {
            setCustomerId(null);
          }
        } catch (error) {
          console.error('Error fetching Stripe customer details:', error);
          setCustomerId(null);
        } finally {
          setLoadingCustomerId(false);
        }
      }
    };

    fetchStripeCustomerDetails(); // Call the function to fetch customer details
  }, [isAuthenticated, user]); // Re-run effect when isAuthenticated or user changes

  return (
    <StripeContext.Provider
      value={{
        customerId,
        setCustomerId,
        subscription,
        setSubscription,
        stripeEmail,
        stripeName,
        loadingCustomerId,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => useContext(StripeContext);
