// useInventory.js
import { useState, useEffect } from 'react';
import { fetchInventorySnapshots } from '../services/fetch-inventory.js';

export function useInventory() {
  const [snapshots, setSnapshots] = useState([]);
  const [inventoryLoading, setInventoryLoading] = useState(true);

  useEffect(() => {
    const getSnapshots = async () => {
      try {
        setInventoryLoading(true);
        const data = await fetchInventorySnapshots();

        setSnapshots(data);
        setInventoryLoading(false);
      } catch (error) {
        console.error('Failed to fetch inventory snapshots:', error);
      } finally {
        setInventoryLoading(false);
      }
    };

    getSnapshots();
  }, []);

  return { snapshots, setSnapshots, inventoryLoading };
}
