const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch all inventory snapshots from database
export async function fetchInventorySnapshots() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/inventory-snapshot`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const rawData = await resp.text();

    if (resp.ok) {
      const data = JSON.parse(rawData);

      // Round off created_at dates to midnight
      // const adjustedData = data.map((snapshot) => {
      //   const createdAtDate = new Date(snapshot.created_at);
      //   createdAtDate.setHours(0, 0, 0, 0); // Set the time to midnight
      //   return {
      //     ...snapshot,
      //     created_at: createdAtDate.toISOString(),
      //   };
      // });
      const adjustedData = data.map((snapshot) => {
        const createdAtDate = new Date(snapshot.created_at);
        const timezoneOffset = createdAtDate.getTimezoneOffset() * 60000;
        const localDate = new Date(createdAtDate.getTime() + timezoneOffset);
        localDate.setHours(0, 0, 0, 0); // Set the time to midnight
        snapshot.created_at = localDate;
        return snapshot;
      });
      return adjustedData;
    } else {
      // eslint-disable-next-line no-console
      console.error(rawData.message);
      throw new Error(rawData.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function postNewSnapshot(snapshot) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/inventory-snapshot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(snapshot),
      credentials: 'include',
    });

    const data = await resp.json();
    // console.log('data', data);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
