const BASE_URL = process.env.REACT_APP_BASE_URL;

// get all Orders from database and display on admin page
export async function fetchOrders() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function insertNewOrder(orderData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderData,
      }),
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function editOrder(orderId, orderData) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders/${orderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderData,
      }),
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateFulfillmentStatus(orderId, isFulfilled) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/orders/${orderId}/fulfillment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isFulfilled,
      }),
      credentials: 'include',
    });
    const data = await resp.json();

    if (resp.ok) {
      return data;
    } else {
      // eslint-disable-next-line no-console
      console.error(data.message);
      throw new Error(data.message);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteOrder = async (orderId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/orders/${orderId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Error deleting order');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
