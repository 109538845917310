import { useEffect, useState } from 'react';
import { fetchOrders } from '../services/fetch-orders.js';

export function useOrders(customerId) {
  const [orders, setOrders] = useState({});
  const [orderError, setOrderError] = useState('');
  const [orderLoading, setOrderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrderLoading(true);
        const orders = await fetchOrders();

        setOrders(orders);
        setOrderLoading(false);
      } catch (e) {
        setOrderError(e.message);
        setOrderLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return {
    orders,
    setOrders,
    orderLoading,
    setOrderLoading,
    orderError,
    setOrderError,
  };
}
