import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AccountProvider } from './context/AccountContext.js';
import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from './context/LoadingContext.js';
import { QueryProvider } from './context/QueryContext.js';
import { ProfileProvider } from './context/ProfileContext.js';
import { AuthProvider } from './context/AuthContext.js';
import { StripeProvider } from './context/StripeContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <AccountProvider>
        <AuthProvider>
          <StripeProvider>
            <QueryProvider>
              <ProfileProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ProfileProvider>
            </QueryProvider>
          </StripeProvider>
        </AuthProvider>
      </AccountProvider>
    </LoadingProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
