import React, { useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  Button,
  Typography,
} from '@mui/material';
import Toast from '../Toast/Toast.js';
import { useState } from 'react';
import { usePosts } from '../../hooks/usePosts.js';
// import '../Dashboard/Dashboard.css';

import FlamePipe from '../FlamePipe/FlamePipe.js';

import ProductGrid from './ProductGrid/ProductGrid.js';
import './ProductGrid/ProductGrid.css';
import InventoryMgtForm from './InventoryMgtForm.js';
import {
  deleteProduct,
  editProducts,
  postProducts,
  uploadProductImage,
} from '../../services/fetch-products.js';
import {
  deleteById,
  deleteImage,
  postAddImages,
  postPost,
  transferProductPic,
  updatePost,
  uploadImagesAndCreatePost,
} from '../../services/fetch-utils.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStripeContext } from '../../context/StripeContext.js';
import exampleProductsImageMobile from '../../assets/products-ex-m.png';
import exampleProductsImageDesktop from '../../assets/products-ex-dt.png';
import { useQuery } from '../../context/QueryContext.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
export default function QuotaTracking({ products, setProducts, error, postError, openToast }) {
  const { loading, setLoading, restricted } = usePosts();

  const { customerId } = useStripeContext();

  const [mode, setMode] = useState('new');
  const [files, setFiles] = useState([]);
  const { setNewPostCreated } = useQuery();
  let [product, setProduct] = useState({
    date: null,
    type: '',
    title: '',
    category: '',
    price: '',
    description: '',
    customer_id: customerId,
    sold: false,
  });

  // state ===============================================================

  // State for new/ edit buttons
  const [selectedButton, setSelectedButton] = useState('new');
  const [editedProduct, setEditedProduct] = useState(null);

  // State to track the selected product's ID
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(product); // default selected product is null

  // state for mobile form display
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [showForm, setShowForm] = useState(false);

  // state for mobile auction update display
  const [showAuctionsToUpdate, setShowAuctionsToUpdate] = useState(false);
  const [createGalleryPost, setCreateGalleryPost] = useState(false);

  // toast component
  const notify = () => toast('Please choose a valid date before submitting');
  // TODO this is the working example of toastify ^^^^^^^^^^^^^^^^

  // State for products
  const [originalProducts, setOriginalProducts] = useState(products);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productSelect, setProductSelect] = useState({ type: 'all' });
  const [originalPublicId, setOriginalPublicId] = useState(product.public_id || '');

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  // --Determine the sliced products to be displayed based on current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // --Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // functions ===============================================================
  const resetForm = () => {
    // setOrderDate(new Date());
    // setClientName('');
    // setShipping(0);
    // setItems([{ itemName: '', quantity: '', rate: '', category: '', description: '' }]);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setMode('new');
    resetForm();
    setSelectedProduct(null);
  };

  const handleNewClick = () => {
    setMode('new');
    setSelectedButton('new');
    setProduct((prev) => ({
      ...prev,
      date: null,
      type: '',
      title: '',
      category: '',
      price: '',
      description: '',
    }));
    setFiles([]); // Reset file state
    setSelectedProduct(null);
  };

  // EDIT button on each product
  const handleEditClick = (productToEdit) => {
    setMode('edit');
    setSelectedButton('edit');
    setProduct(productToEdit);
    setFiles([]);
    if (isMobile) {
      setShowForm(true);
    }
  };

  // DELETE button on each product
  const handleDeleteClick = async (product) => {
    try {
      // Delete image from Cloudinary if it exists
      if (product.public_id) {
        await deleteImage(product.public_id, 'image'); // Assuming 'image' is the resource type
      }

      // Delete corresponding post if it exists
      if (product.post_id) {
        await deleteById(product.post_id);
      }

      // Delete the product
      const response = await deleteProduct(product.id);
      if (response) {
        setProducts((prevProducts) => {
          const updatedProducts = prevProducts.filter((p) => p.id !== product.id);
          updatedProducts.sort((a, b) => Number(b.date) - Number(a.date));
          setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post deletion

          return updatedProducts;
        });
      }
    } catch (error) {
      console.error('Failed to delete product:', error);
    }
  };

  //  SUBMIT on 'new' mode, handle form data and send it to the backend
  const handleAddProduct = async (productData) => {
    try {
      const response = await postProducts(productData);

      setFiles([]); // Reset file state

      setProduct((prev) => ({ ...prev, customer_id: customerId }));
      if (response) {
        setProducts((prevProducts) => {
          // Create a new array with the new product
          const updatedProducts = [...prevProducts, response];

          // Sort the array by the 'date' property

          updatedProducts.sort((b, a) => Number(a.date) - Number(b.date));

          return updatedProducts;
        });
      }
    } catch (error) {
      // handle any errors, maybe set an error state or show a toast
      console.error('Failed to add product:', error);
    }
  };

  //  SUBMIT on 'edit' mode, handle form data and send it to the backend
  const handleEditProduct = async (productData) => {
    try {
      setProduct((prev) => ({ ...prev, id: selectedProductId }));
      setProduct((prev) => ({ ...prev, customer_id: customerId }));

      // Send the edited product data to quota tracking
      const response = await editProducts(productData);

      if (productData.post_id) {
        // Update corresponding post in posts (gallery_posts table) with edit
        await updatePost(productData.post_id, productData);
      }

      if (response) {
        setProducts((prevProducts) => {
          // Map through the existing products to update the edited product
          const updatedProducts = prevProducts.map((p) => {
            if (p.id === productData.id) {
              // Replace with the updated product data
              return response;
            }
            return p;
          });

          // Sort the array by the 'date' property
          updatedProducts.sort((a, b) => Number(b.date) - Number(a.date));

          return updatedProducts;
        });
        // Update the product state with the selected product's details

        setMode('new');
        setProduct((prev) => ({
          ...prev,
          date: null,
          type: '',
          title: '',
          category: '',
          price: '',
          description: '',
          customer_id: customerId,
        }));
      }
    } catch (error) {
      // handle any errors, maybe set an error state or show a toast
      console.error('Failed to edit product:', error);
    }
  };

  const handleProductSelection = (e) => {
    const selectedId = e.target.value;
    setSelectedProductId(selectedId); // Update the selected product ID
    setProduct(selectedId); // Update the product state with the selected product's ID
    const foundProduct = products.find((p) => p.id === selectedId);

    // Update the form with the selected product's details
    if (foundProduct) {
      setProduct({
        date: foundProduct.date,
        type: foundProduct.type,
        title: foundProduct.title,
        category: foundProduct.category,
        price: foundProduct.price,
        description: foundProduct.description,
        sold: foundProduct.sold,
      });
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    // handle dropdowns so they display selection

    if (
      name === 'type' ||
      name === 'category' ||
      name === 'date' ||
      name === 'title' ||
      name === 'description' ||
      name === 'num_days' ||
      name === 'sold'
    ) {
      // add soldInput state from form to product state
      setProduct((prev) => ({
        ...prev,
        [name]: name === 'sold' ? value === 'true' : value,
      }));

      // make sure input is a number and not negative
      // } else if (name === 'price' && !isNaN(value) && +value >= 0) {
    } else if (name === 'price' && !isNaN(value)) {
      setProduct((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleProductEditChange = (e) => {
    setSelectedProduct((prevSelectedProduct) => ({
      ...prevSelectedProduct,
      [name]: value,
    }));
    const { name, value } = e.target;
    setEditedProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true); // Assuming you have a loading state

    // Check if the date is valid-  'required' isn't inherently supported in MUI's DatePicker so this is a manual check mitigating that
    if (!product.date) {
      toast.error('Date is required', { theme: 'dark' });
      return; // Stop form submission if the date is not valid
    }

    try {
      let updatedProduct = { ...product };
      let uploadedImages = [];

      // Upload images if there are files selected
      if (files.length > 0) {
        uploadedImages = await uploadProductImage(files);
        // console.log('uploadedImages', uploadedImages);

        if (uploadedImages && uploadedImages.length > 0) {
          updatedProduct = {
            ...updatedProduct,
            image_url: uploadedImages[0].secure_url,
            public_id: uploadedImages[0].public_id,
          };
        }
      } else if (mode === 'edit' && !files.length && !product.image_url) {
        // In edit mode, if no existing image, reset image fields
        updatedProduct = {
          ...updatedProduct,
          image_url: '',
          public_id: '',
        };
      }

      //
      //
      //

      let newProductPost = null;
      // If the createGalleryPost checkbox is checked so create new post in gallery
      if (createGalleryPost) {
        const { title, description, image_url, category, price, public_id, sold } = updatedProduct;

        // Create new post with fetch call to DB
        const post = await postPost(
          title,
          description,
          image_url,
          category,
          price,
          public_id,
          files.length, // num_imgs default to 1 since there is only 1 image for each product listing- //TODO  user needs to add more manually to post
          sold
        );

        const adaptedFiles = files.slice(1); // added to remove duplicate url
        // Upload new images to Cloudinary and get their URLs + post details
        newProductPost = {
          ...(await uploadImagesAndCreatePost(adaptedFiles, mode)),
          ...newProductPost,
        };

        // send image urls and public ids to db
        await postAddImages(newProductPost.additionalImages, post.id);

        // await transferProductPic(post.id); // not sure why this was here- removed 9/15/24 to remove extra image
        // in gallery post detail when creating new post from quota tracking

        updatedProduct.post_id = post.id;

        setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post deletion

        // send image urls and public ids to db
        await postAddImages(uploadedImages, post.id);

        if (!post) {
          throw new Error('Failed to create gallery post');
        }
      }

      // Trigger proper mode function
      if (mode === 'edit') {
        // Update the product in the database
        await handleEditProduct(updatedProduct);
      } else if (mode === 'new') {
        // Add a new product to the database
        await handleAddProduct(updatedProduct);
      }

      if (newProductPost) {
        // Update the posts state to include the new post
        // setPosts((prevPosts) => [...prevPosts, newPost]);
        setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post deletion
      }
      if (mode === 'new') {
        // After adding a new product, reset currentImage
        setCurrentImage('');
      }

      // Clear the form and reset states
      setSelectedProduct(null);
      setProduct({
        type: '',
        date: null,
        title: '',
        category: '',
        price: '',
        description: '',
        num_days: '',
        sold: false,
      });
      setFiles([]); // Reset file state
      setLoading(false); // Reset loading state

      handleFormClose();
    } catch (error) {
      console.error('Failed to submit product:', error);
      setLoading(false); // Reset loading state in case of an error
    }
  };

  useEffect(() => {
    setOriginalProducts(products);
    setFilteredProducts(products); // Initialize filteredProducts with the latest products for after display type change in dropdown
  }, [products]);

  const handleTypeSelectChange = (e) => {
    const { value } = e.target;
    setProductSelect((prev) => ({ ...prev, type: value })); // state for dropdown display
    setCurrentPage(1);
    if (value === 'all') {
      // Show all products
      setFilteredProducts(originalProducts);
    } else if (value === 'highlight') {
      // Filter the products to show only the highlighted ones- auctions with 0 price
      const newFilteredProducts = originalProducts.filter(
        (product) => product.price === '0' && product.type === 'auction'
      );
      setFilteredProducts(newFilteredProducts);
    } else {
      // Filter the products to show only the selected type
      const newFilteredProducts = originalProducts.filter((product) => product.type === value);
      setFilteredProducts(newFilteredProducts);
    }
  };

  const handleShowAuctionsToUpdate = () => {
    const newFilteredProducts = originalProducts.filter(
      (product) => product.price === '0' && product.type === 'auction'
    );
    setFilteredProducts(newFilteredProducts);
    setShowAuctionsToUpdate(true);
    setCurrentPage(1);
  };

  const handleShowAllProducts = () => {
    setFilteredProducts(originalProducts);
    setShowAuctionsToUpdate(false);
  };

  // When entering edit mode, set the original public ID
  useEffect(() => {
    if (mode === 'edit' && product.public_id) {
      setOriginalPublicId(product.public_id);
    }
  }, [mode, product.public_id]);

  // this is for making the date picker work- '?' is a null check to prevent errors after submitting the form on edit
  const dateAsString = selectedProduct?.date; // e.g., "2023-11-14"
  const dateAsNumber = Number(dateAsString);
  const [currentImage, setCurrentImage] = useState(product.image_url || '');

  if (openToast) {
    let toastMessage = error || postError;

    if (postError === 'Failed to fetch') {
      toastMessage = `Something went wrong fetching your posts. Please refresh the page or try again later.`;
    }

    const toast = { message: toastMessage };
    return <Toast toast={toast} />;
  }
  if (error || postError) {
    if (postError === 'Failed to fetch') {
      const toast = {
        message: `Something went wrong fetching your posts. Please refresh the page or try again later.`,
      };
      return <Toast toast={toast} />;
    } else if (error) {
      const toast = {
        message: error.message,
      };
      return <Toast toast={toast} />;
    }
  }

  return (
    (loading && (
      <div className="loading-dash">
        <FlamePipe />
      </div>
    )) || (
      <>
        <Box
          className="admin-container-2"
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            color: (theme) => theme.palette.primary.light,
            paddingTop: '1rem',
            transform: 'translateY(-2.5%)',
          }}
        >
          <Box
            sx={{ borderColor: (theme) => theme.palette.primary.dark }}
            className="admin-panel-2"
          >
            <section className="admin-panel-section-2 ">
              <div className="button-container">
                <Typography variant="h5">Quota Tracking</Typography>
                <Typography variant="h6">Show Category:</Typography>
                <div className="inner-button-container">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="product-type-label">Select Type</InputLabel>
                    <Select
                      sx={{ height: '45px' }}
                      labelId="product-category-label"
                      name="type-select"
                      value={productSelect ? productSelect.type : 'All'}
                      onChange={handleTypeSelectChange}
                      label="Type Select"
                      required
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="highlight">Auction Update</MenuItem>
                      <MenuItem value="auction">Auctions</MenuItem>
                      <MenuItem value="direct-sale">Direct Sales</MenuItem>
                      <MenuItem value="inventory">Inventory</MenuItem>
                      <MenuItem value="prep-other">Prep/ Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* <div className="temp-fix"></div> */}
              </div>
            </section>
          </Box>

          {isMobile && !showForm && (
            <>
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowForm(true)}
                sx={{ maxHeight: '2rem', margin: '0 1rem 0 1rem' }}
                disabled={restricted ? restricted : false}
                startIcon={<AddCircleIcon />}
              >
                {restricted ? 'Tracking disabled' : 'Create New Product'}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={showAuctionsToUpdate ? handleShowAllProducts : handleShowAuctionsToUpdate}
                sx={{ margin: '.5rem 1rem 0 1rem' }}
                startIcon={<UpdateIcon />}
              >
                {showAuctionsToUpdate ? 'Show All Products' : 'Update Auctions'}
              </Button>
              <Box
                display="flex"
                justifyContent={isMobile ? 'flex-start' : 'center'}
                sx={{
                  color: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="h5" sx={{ margin: '.5rem 0 0 1rem' }}>
                  {showAuctionsToUpdate ? 'Auctions to Update' : 'All Products'}
                </Typography>
              </Box>
            </>
          )}

          {!showForm && (
            <Box className="product-grid-container" sx={{ overflowY: 'scroll' }}>
              {products.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>No data available. Example display below:</Typography>
                  <img
                    src={isMobile ? exampleProductsImageMobile : exampleProductsImageDesktop}
                    alt="example"
                    style={{ width: '80%' }}
                  />
                </Box>
              ) : (
                <ProductGrid
                  products={currentProducts}
                  setSelectedProduct={setSelectedProduct}
                  selectedProduct={selectedProduct}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  filteredProducts={filteredProducts}
                  paginate={paginate}
                  currentPage={currentPage}
                  indexOfLastProduct={indexOfLastProduct}
                  productsPerPage={productsPerPage}
                />
              )}
            </Box>
          )}

          {(showForm || !isMobile) && (
            <InventoryMgtForm
              selectedButton={selectedButton}
              handleNewClick={handleNewClick}
              handleEditClick={handleEditClick}
              mode={mode}
              handleProductSubmit={handleProductSubmit}
              handleProductChange={handleProductChange}
              product={product}
              products={products}
              handleProductSelection={handleProductSelection}
              selectedProduct={selectedProduct}
              handleProductEditChange={handleProductEditChange}
              setEditedProduct={setEditedProduct}
              dateAsNumber={dateAsNumber}
              setProduct={setProduct}
              setProducts={setProducts}
              selectedProductId={selectedProductId}
              files={files}
              setFiles={setFiles}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              handleFormClose={handleFormClose}
              createGalleryPost={createGalleryPost}
              setCreateGalleryPost={setCreateGalleryPost}
            />
          )}
        </Box>
      </>
    )
  );
}
