import { useState, useEffect } from 'react';
import { fetchPosts } from '../services/fetch-utils.js';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext.js';
import { useStripeContext } from '../context/StripeContext.js';

export function usePosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [postError, setPostError] = useState(null);
  const [restricted, setRestricted] = useState(false);
  const { setCustomerId } = useStripeContext();
  const { user, setUser, setError, email, handleSignOut } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // If the user is not authenticated, return early to avoid the API call
        if (!user) {
          setLoading(false);
          return;
        }
        const data = await fetchPosts();

        data.posts ? setPosts(data.posts) : setPosts(null);
        setRestricted(data.restricted);
        setLoading(false);
      } catch (e) {
        console.log('Error caught in usePosts hook:', e);

        if (e.status === 401) {
          handleSignOut(email, setUser, setError, setCustomerId);
          navigate('/');
          return;
        }

        if (e.status === 403) {
          navigate('/');
          return;
        }

        setPostError(e.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [email, handleSignOut, setError, setUser, setCustomerId, navigate]); // TODO investigate why this is missing user

  return { posts, setPosts, loading, postError, setLoading, restricted };
}
