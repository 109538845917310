import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext.js';

export default function SubmitButton({ code, stage, setStage }) {
  const {
    error, // <-- Access the error state from AuthContext
    email,
    password,
    setPassword,
    setCPassword,
    cPassword,
    handleRegistration,
    openToast,
    setOpenToast,
    handleForgotPassword,
  } = useAuthContext();

  useEffect(() => {
    if (openToast) {
      const timer = setTimeout(() => setOpenToast(false), 10000);
      return () => clearTimeout(timer);
    }
  }, [openToast, setOpenToast]);

  const { type } = useParams();

  const handleAuthButton = (e) => {
    switch (type) {
      case 'sign-up':
        // return handleRegistration(e, type);
        return handleRegistration(e, type, password, cPassword);

      case 'sign-in':
        return handleRegistration(e, type, password, cPassword);
      // return handleRegistration(e, type);

      case 'forgot-password':
        return handleForgotPassword({
          code,
          stage,
          setStage,
          email,
          password,
          setPassword,
          cPassword,
          setCPassword,
        });

      default:
        break;
    }
  };

  return (
    <>
      <Button
        data-testid="submit-button"
        color={error ? 'error' : 'primary'}
        variant="contained"
        size="small"
        type="submit"
        onClick={(e) => handleAuthButton(e)}
      >
        {type === 'sign-in' ? 'Sign In' : type === 'sign-up' ? 'Sign Up' : 'Submit'}
      </Button>
    </>
  );
}
