import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import '../../Subscription.css';

import { fetchStripeSubscribe } from '../../../../services/stripe.js';

import SubscriptionButtons from './SubscriptionButtons.js';
import PurchaseFormInfo from './PurchaseFormInfo.js';
import SubscriptionInputs from './SubscriptionInputs.js';
import { useAuthContext } from '../../../../context/AuthContext.js';
import { useStripeContext } from '../../../../context/StripeContext.js';

export default function SubscriptionFormPage({ setStage }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');

  const { customerId, stripeName, stripeEmail } = useStripeContext();
  const { user } = useAuthContext();

  // Prefill customer information if it exists
  useEffect(() => {
    if (customerId) {
      // Split the stripeName into first and last names
      const [first, ...last] = stripeName.split(' ');
      setFirstName(first || ''); // If first is undefined, set to empty string
      setLastName(last.join(' ') || ''); // Join the rest as last name, if undefined set to empty string
      setBillingEmail(stripeEmail || ''); // Set billing email, if undefined set to empty string
    }
  }, [customerId, stripeEmail, stripeName]);

  const handleClickSubscribe = async (e) => {
    const priceId = e.target.value;

    const data = await fetchStripeSubscribe({
      priceId,
      firstName,
      lastName,
      billingEmail,
      awsSub: user,
      setStage,
      customerId,
    });

    return data;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <PurchaseFormInfo />

      <Container
        className="purchase-subscription-container"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
        }}
      >
        {customerId ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h6">Your Information:</Typography>
            <Typography>
              {firstName} {lastName}
            </Typography>
            <Typography>{billingEmail}</Typography>

            <SubscriptionButtons {...{ handleClickSubscribe }} />
          </Box>
        ) : (
          <Box
            component={'form'}
            className="purchase-form"
            onClick={(e) => e.preventDefault()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: (theme) => theme.spacing(2),
              justifyContent: 'flex-start',
            }}
          >
            <SubscriptionInputs
              {...{ firstName, setFirstName, lastName, setLastName, billingEmail, setBillingEmail }}
            />

            <SubscriptionButtons {...{ handleClickSubscribe }} />
          </Box>
        )}
      </Container>

      <Button variant="contained" color="primary" onClick={() => setStage(0)}>
        Back
      </Button>
    </Box>
  );
}
