const BASE_URL = process.env.REACT_APP_BASE_URL;
/* eslint-disable no-console */
const createCookies = async (result) => {
  try {
    const cookies = await fetch(`${BASE_URL}/api/v1/auth/create-cookies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(result),
    });
    return cookies;
  } catch (e) {
    e.message = 'Failed to create cookies';
    console.error(e);
    throw e;
  }
};

const deleteCookies = async () => {
  try {
    const data = await fetch(`${BASE_URL}/api/v1/auth/clear-cookies`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application',
      },
      credentials: 'include',
    });

    // return data.json();  // removed to try to get the 204 status instead of 200

    // Check if the response was successful
    if (data.ok) {
      return;
      // console.log('Cookies cleared successfully');
    } else {
      console.error('Failed to clear cookies');
    }
  } catch (e) {
    console.error(e);
    e.message = 'Failed to clear cookies';
    // throw e;
  }
};

export { createCookies, deleteCookies };
