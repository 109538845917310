/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@mui/material';
import { useTheme } from '@emotion/react';
import './Subscription.css';

import { useAccount } from '../../context/AccountContext.js';
import useLoadingState from '../../context/LoadingContext.js';

import SubscriptionLandingPage from './SubscriptionPages/LandingPageComponents/SubscriptionLandingPage.js';
import SubscriptionFormPage from './SubscriptionPages/FormPageComponents/SubscriptionFormPage.js';
import Cancel from './SubscriptionPages/Cancel.js';
import Success from './SubscriptionPages/Success.js';

export default function Subscription() {
  const [stage, setStage] = useState(0);
  const { pageLoading } = useLoadingState();
  const { result } = useParams();
  const theme = useTheme();

  // Retrieve the state from local storage and set it
  useEffect(() => {
    const storedState = localStorage.getItem('subscriptionStage');
    if (storedState) {
      try {
        const parsedState = JSON.parse(storedState);
        setStage(parsedState);
      } catch (error) {
        console.error('Error parsing stored state:', error);
      }
    }
  }, []);

  // Save the state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('subscriptionStage', JSON.stringify(stage));
  }, [stage]);

  // set stage based on result on component mount
  useEffect(() => {
    switch (result) {
      case 'success':
        setStage(2);
        break;
      case 'cancel':
        setStage(2);
        break;
      case 'dashboard':
        setStage(2);
        break;
      default:
        setStage(0);
        break;
    }
  }, [result]);

  return (
    <>
      {pageLoading ? (
        <CircularProgress />
      ) : (
        <Box className={'subscribe-wrapper'}>
          <Container className="subscribe-container" maxWidth="xl" sx={{ gap: theme.spacing(3) }}>
            {stage === 0 && <SubscriptionLandingPage setStage={setStage} />}
            {stage === 1 && <SubscriptionFormPage setStage={setStage} />}
            {stage === 2 &&
              (result === 'success' ? <Success /> : result === 'cancel' ? <Cancel /> : null)}
          </Container>
        </Box>
      )}
    </>
  );
}
