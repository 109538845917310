import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import '../../Subscription.css';
import useLoadingState from '../../../../context/LoadingContext.js';
import LandingPageInfo from './LandingPageInfo.js';
import ExampleImages from './ExampleImage.js';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext.js';
import PromotionalVideo from './PromotionalVideo.js';
import { useTheme } from '@emotion/react';

export default function SubscriptionLandingPage({ setStage }) {
  const { setPageLoading } = useLoadingState();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignInToSubscribe = () => {
    navigate('/auth/sign-up');
  };

  const handleStageChange = () => {
    setPageLoading(true);
    setStage(1);
    const timeout = setTimeout(() => {
      setPageLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Typography variant="h4" textAlign={'left'} sx={{ borderBottom: '2px solid green' }}>
        Become a subscriber!
      </Typography>
      <Grid container spacing={4} alignItems="start" sx={{ padding: '20px' }}>
        {/* Text and Video Section */}

        <Grid item xs={12} md={6}>
          <LandingPageInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <PromotionalVideo />
          {/* Action Button */}
          <Grid item xs={12} sx={{ marginTop: '5%' }}>
            <Box textAlign="center">
              {isAuthenticated ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleStageChange}
                  sx={{ mb: 2 }}
                >
                  Purchase a Subscription
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSignInToSubscribe}
                  sx={{ mb: 2 }}
                >
                  Sign-up to Subscribe
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        {/* Example Images */}
        <Grid item xs={12}>
          {/* <ExampleImages /> */}
        </Grid>
      </Grid>
    </Box>
  );
}
