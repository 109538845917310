import React from 'react';

const PromotionalVideo = () => {
  const videoUrl =
    'https://res.cloudinary.com/dzodr2cdk/video/upload/v1726382185/at-the-fire-ASSETS/promo-video_x2a2hq.mp4';
  return (
    <video
      controls
      width="100%"
      poster="https://res.cloudinary.com/dzodr2cdk/image/upload/v1726362447/at-the-fire-ASSETS/logo-icon-6-512_x_911_wdxnoe.png"
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default PromotionalVideo;
