import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext.js';

function StripeReturn() {
  const navigate = useNavigate();
  const { isAuthenticated, loadingAuth } = useAuthContext();

  useEffect(() => {
    if (!loadingAuth) {
      if (isAuthenticated) {
        navigate('/dashboard');
      } else {
        navigate('/auth/sign-in');
      }
    }
  }, [loadingAuth, navigate, isAuthenticated]);

  return null;
}

export default StripeReturn;
